import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Col, getItemWithExpiration, Row } from '../../lib/utils';
import { Body2Bold, Body3Regular, CaptionLight, Subtitle1Bold } from '../../lib/font';
import ColTitleAndTextInput from '../ColTitleAndTextInput';
import dayjs from 'dayjs';
import MemoTextInput from '../TextInput/MemoTextInput';
import { MemberMembershipDataType } from '../../services/useMembershipQueryService';
import Modal from '../modal/Modal';
import { colors } from '../../lib/colors';
import ModalUpdateMembershipName from './ModalUpdateMembershipName';
import useMembershipService from '../../services/useMembershipService';
import { useQueryClient } from '@tanstack/react-query';
import Dropdown from '../DropDown/DropDown';
import { RegularText } from '../../lib/Text';
import ModalSalesManagerName from './ModalSalesManagerName';
import ModalUpdateAlert from './ModalUpdateAlert';
import useSingleQuery from '../../hook/useSingleQuery';
import { fetchGetSalesManagers, SalesManager } from '../../services/useMembershipPaymentsQueryService';
import useMembershipPaymentsMutationService from '../../services/useMembershipPaymentsMutationService';
import { CodeValueType } from '../../types/types';
import NewCalendar from '../Calendar/NewCalendar';

type Props = {
    memberName?: string;
    coachName?: string;
    membership: MemberMembershipDataType;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    createAlert: any;
};

const ModalMembershipUpdate = ({ memberName, coachName, membership, setIsVisible, createAlert }: Props) => {
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const queryClient = useQueryClient();
    // 판매담당자이름
    const [salesManagerName, setSalesManagerName] = useState<string>('');

    const { postSalesManagerMutation } = useMembershipPaymentsMutationService({
        onPostSalesManagerSuccess(data) {
            setSalesManager(resfineSalesManagers(data));

            // salesManagerName 으로 data 에서 userName 같은거 찾아서 setSalesManagerName
            data.forEach((item: any) => {
                if (item.userName === salesManagerName) {
                    setSelectedSalesManager(item);
                    return;
                }
            });
        },
    });
    const { refinedResult, isLoading, isFetching } = useSingleQuery({
        fetchFn: fetchGetSalesManagers,
        params: { centerId: authObject.centerId },
        enabled: !!authorization,
        suspense: false,
    });

    // userId, userName -> code, value
    // 마지막에 직접입력 추가
    const resfineSalesManagers = useCallback(
        (data: any): Array<{ code: string; value: string; onClick?: () => void }> => {
            // userId, userName -> code, value 변환
            const _salesManagers = Array.isArray(data)
                ? data.map((item: any) => ({
                      code: item.userId,
                      value: item.userName,
                      object: item,
                  }))
                : [];

            // 직접입력 추가
            return [
                ..._salesManagers,
                {
                    code: 'direct',
                    value: '직접입력',
                    onClick: () => setIsSalesManagerModalVisible(true),
                },
            ];
        },
        []
    );
    const [salesManager, setSalesManager] = useState<CodeValueType[] | undefined>(undefined);

    useEffect(() => {
        if (!isLoading && !isFetching) {
            setSalesManager(resfineSalesManagers(refinedResult.data));
        }
    }, [isLoading, isFetching]);
    const { updateMemberShipMutation } = useMembershipService({
        onUpdatedMemberShipSuccess() {
            createAlert('수강권 수정 완료', '수강권이 성공적으로 수정되었습니다 !', false);
            queryClient.invalidateQueries({
                queryKey: ['getMembershipForMemberQuery'],
            });

            setIsVisible(false);
        },
    });
    // 수강권 이름 수정 모달
    const [isMembershipNameModalVisible, setIsMembershipNameModalVisible] = useState(false);
    // 판매 담당자 이름 추가 모달
    const [isSalesManagerModalVisible, setIsSalesManagerModalVisible] = useState(false);
    // 수정시 경고 모달
    const [isAlertModalVisible, setIsAlertModalVisible] = useState(false);
    // 한번만 수정시 경고 줘야함
    const [isCheckUpdate, setIsCheckUpdate] = useState(false);
    // 달력 열기
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);

    // 회당 가격
    const [pricePerClass, setPricePerClass] = useState<number>(membership.pricePerSchedule);
    // 판매 금액
    const [totalPrice, setTotalPrice] = useState<number>(membership.totalPrice);

    // 시작 날짜
    const [startDate, setStartDate] = useState<string>(membership.activationDate);
    // 만료 날짜 expirationDate
    const [expirationDate, setExpirationDate] = useState<string>(membership.expirationDate);
    // 수강권 기간
    const [membershipPeriod, setMembershipPeriod] = useState<number>(
        dayjs(expirationDate).diff(dayjs(startDate), 'day')
    );
    // 시작 회차 startSession
    const [startSession, setStartSession] = useState<number>(membership.startSessionValue);
    // 총 회차 totalSession
    const [totalSession, setTotalSession] = useState<number>(membership.totalSession);
    // 수업 시간
    const [classTime, setClassTime] = useState<number>(membership.period);
    // 메모
    const [memo, setMemo] = useState<string>(membership.memo);
    // BGColor
    const [BGColor, setBGColor] = useState<string[]>(membership.BGColor);
    // 수강권 이름
    const [membershipName, setMembershipName] = useState<string>(membership.name);
    // 결제일자
    const [paymentDate, setPaymentDate] = useState<string>(membership?.initialPaymentDate ?? dayjs().toISOString());
    // 판매담당자
    const [selectedSalesManager, setSelectedSalesManager] = useState<SalesManager | undefined>(membership.salesManager);
    // 회원 구분
    const [memberType, setMemberType] = useState<string>(membership.isNewSales ? 'new' : 're');

    const onClickUpdateMembership = () => {
        // 수업시간이 10분 단위가 아니라면 경고창
        if (classTime % 10 !== 0) {
            createAlert('올바르지 않은 입력', '수업 시간을 10분단위로 입력하여 주세요', true);

            return;
        }
        // 시작회차가 총회차 보다 크다면 경고창
        else if (startSession > totalSession) {
            createAlert('올바르지 않은 입력', '시작회차를 총회차보다 낮게 설정해주세요!', true);

            return;
        }
        //시작날짜가 만료날짜보다 크다면 경고창
        else if (dayjs(startDate).isAfter(dayjs(expirationDate))) {
            createAlert('올바르지 않은 입력', '시작날짜를 만료날짜보다 더 낮게 설정해주세요!', true);

            return;
        }
        if (selectedSalesManager === undefined) return;

        updateMemberShipMutation.mutate({
            membershipId: membership._id,
            name: membershipName,
            period: classTime,
            activationDate: startDate,
            expirationDate: expirationDate,
            startSessionValue: startSession,
            totalSession: totalSession,
            memo: memo,
            pricePerSchedule: pricePerClass,
            totalPrice: totalPrice,
            accessToken: authorization,
            isNewSales: memberType === 'new',
            initialPaymentDate: paymentDate,
            salesManager: selectedSalesManager,
        });
    };

    return (
        <Modal
            whiteViewHeight={800}
            whiteViewWidth={1080}
            setIsVisible={setIsVisible}
            isCloseOutside={false}
            isCloseButton
            onClose={() => setIsVisible(false)}
        >
            <Col className="flex py-[36px] pl-[66px] pr-[98px]">
                <Row className="mb-[24px]">
                    <Subtitle1Bold>수강권 수정하기</Subtitle1Bold>
                </Row>
                <Row className="mb-[32px]">
                    <Col className="mr-[40px]">
                        <Col
                            style={{
                                width: 264,
                                height: 120,
                                background:
                                    BGColor && BGColor?.length > 0
                                        ? `linear-gradient(135deg, ${BGColor[0]} 0%, ${BGColor[1]} 100%)`
                                        : colors.GREY_200,
                                borderRadius: 8,
                                justifyContent: 'center',
                                paddingRight: 24,
                                paddingLeft: 24,
                                marginBottom: 24,
                            }}
                        >
                            <Body2Bold className="text-white">{`${membershipName}`}</Body2Bold>
                        </Col>
                        {/* <NewWhiteRoundButton
                            text="수강권 이름 수정하기"
                            onClick={() => setIsMembershipNameModalVisible(true)}
                            fontType="CaptionRegular"
                            width={136}
                            height={24}
                            mb={52}
                        /> */}
                        <ColTitleAndTextInput
                            title="회당 가격"
                            value={pricePerClass.toString()}
                            onChange={(value) => {
                                if (value === '') {
                                    value = '0';
                                }
                                setPricePerClass(parseInt(value));
                            }}
                            width={264}
                            height={36}
                            onClear={() => {
                                setPricePerClass(0);
                            }}
                            textInputRightIcon={false}
                            mb={32}
                            isClearIcon={false}
                            // onFocus={() => {
                            //     if (!isCheckUpdate) {
                            //         setIsAlertModalVisible(true);
                            //         setIsCheckUpdate(true);
                            //     }
                            // }}
                        />
                        <ColTitleAndTextInput
                            title="판매 금액"
                            value={totalPrice.toString()}
                            onChange={(value) => {
                                if (value === '') {
                                    value = '0';
                                }
                                setTotalPrice(parseInt(value));
                            }}
                            width={264}
                            height={36}
                            onClear={() => {
                                setTotalPrice(0);
                            }}
                            textInputRightIcon={false}
                            isClearIcon={false}
                            onFocus={() => {
                                if (!isCheckUpdate) {
                                    setIsAlertModalVisible(true);
                                    setIsCheckUpdate(true);
                                }
                            }}
                            mb={32}
                        />
                    </Col>
                    <Col className="w-[650px]  rounded-[8px] p-[32px]">
                        <Row className="mb-[48px]">
                            <ColTitleAndTextInput
                                title="담당 강사"
                                value={coachName}
                                onChange={() => {}}
                                width={240}
                                height={36}
                                textInputRightIcon={false}
                                textInputLeftIcon={false}
                                disabled
                                mr={60}
                            />
                            <ColTitleAndTextInput
                                title="회원"
                                value={memberName}
                                onChange={() => {}}
                                width={240}
                                height={36}
                                textInputRightIcon={false}
                                textInputLeftIcon={false}
                                disabled
                            />
                        </Row>
                        <Row className="mb-[48px]">
                            <ColTitleAndTextInput
                                title="시작 날짜"
                                value={startDate}
                                isCalendar
                                onClickDate={(date) => {
                                    setStartDate(dayjs(date).toISOString());
                                    setMembershipPeriod(dayjs(expirationDate).diff(dayjs(date), 'day'));
                                }}
                                width={240}
                                height={36}
                                mr={60}
                            />
                            <ColTitleAndTextInput
                                title="만료 날짜"
                                value={expirationDate}
                                isCalendar
                                onClickDate={(date) => {
                                    setExpirationDate(dayjs(date).toISOString());
                                    setMembershipPeriod(dayjs(date).diff(dayjs(startDate), 'day'));
                                }}
                                width={240}
                                height={36}
                            />
                        </Row>
                        <Row className=" items-end mb-[48px]">
                            <ColTitleAndTextInput
                                title="수강권 기간"
                                value={membershipPeriod.toString()}
                                onChange={(value) => {
                                    if (value === '') {
                                        value = '0';
                                    }
                                    setMembershipPeriod(parseInt(value));
                                    setExpirationDate(dayjs(startDate).add(parseInt(value), 'day').toISOString());
                                }}
                                width={140}
                                height={36}
                                mr={32}
                                isClearIcon={false}
                            />
                            <Col>
                                <CaptionLight className="text-WHITE_900">
                                    시작, 만료날짜를 입력하시면 유효기간이 자동으로 계산 되어요!
                                </CaptionLight>
                                <CaptionLight className="text-WHITE_900">
                                    만료 날짜가 지나도 수업 회차가 남으면 만료되지 않아요!
                                </CaptionLight>
                            </Col>
                        </Row>
                        <Row className="mb-[48px]">
                            <ColTitleAndTextInput
                                title="시작 회차"
                                value={startSession.toString()}
                                onChange={(value) => {
                                    if (value === '') {
                                        value = '0';
                                    }
                                    setStartSession(parseInt(value));
                                }}
                                width={140}
                                height={36}
                                mr={60}
                                textInputRightIcon={false}
                                isClearIcon={false}
                            />
                            <ColTitleAndTextInput
                                title="총 회차"
                                value={totalSession.toString()}
                                onChange={(value) => {
                                    if (value === '') {
                                        value = '0';
                                    }
                                    setTotalSession(parseInt(value));
                                }}
                                width={140}
                                height={36}
                                textInputRightIcon={false}
                                isClearIcon={false}
                                mr={60}
                                onFocus={() => {
                                    if (!isCheckUpdate) {
                                        setIsAlertModalVisible(true);
                                        setIsCheckUpdate(true);
                                    }
                                }}
                            />
                            <ColTitleAndTextInput
                                title="수업 시간"
                                value={classTime}
                                onChange={(value) => {
                                    if (value === '') {
                                        value = '0';
                                    }
                                    setClassTime(parseInt(value));
                                }}
                                width={140}
                                height={36}
                                textInputRightIcon={false}
                                isClearIcon={false}
                            />
                        </Row>
                        <Row className="mb-[48px]">
                            <Col className="mr-[60px]">
                                <Body3Regular className="mb-[8px]">판매담당자</Body3Regular>
                                {!isLoading && salesManager && (
                                    <Dropdown
                                        options={salesManager}
                                        bgColor="white"
                                        className="w-[140px] h-[36px] border border-solid border-[#D4DBE8] justify-between"
                                        borderRadius={50}
                                        arrowColor={colors.primary}
                                        onSelect={(item) => {
                                            setSelectedSalesManager(item.object ?? undefined);
                                        }}
                                        initialSelected={selectedSalesManager?.userId}
                                        isAllOption={false}
                                    />
                                )}
                            </Col>
                            <Col className="mr-[60px]">
                                <Body3Regular className="mb-[8px]">회원 구분</Body3Regular>
                                <Dropdown
                                    options={[
                                        { code: 'new', value: '신규' },
                                        { code: 're', value: '재등록' },
                                    ]}
                                    bgColor="white"
                                    className="w-[140px] h-[36px] border border-solid border-[#D4DBE8] justify-between"
                                    borderRadius={50}
                                    arrowColor={colors.primary}
                                    isAllOption={false}
                                    onSelect={(item) => {
                                        setMemberType(item.code);
                                    }}
                                />
                            </Col>
                            <Col onClick={() => setIsCalendarOpen(true)}>
                                <Body3Regular color={colors.LAVEL_4} className="mb-[8px]">
                                    결제일자
                                </Body3Regular>
                                <Col
                                    className={`w-[150px] h-[36px] border border-solid border-[#D4DBE8] pl-[16px] justify-center  rounded-full cursor-pointer`}
                                >
                                    <Body3Regular color={colors.LAVEL_4}>
                                        {dayjs(paymentDate).format('YYYY-MM-DD')}
                                    </Body3Regular>
                                </Col>
                            </Col>
                        </Row>
                        <Col>
                            <Body3Regular className="mb-[6px]">결제 메모</Body3Regular>
                            <CaptionLight className="mb-[8px]" color="#62656B">
                                수강권의 메모는 관리자님과 강사님만 확인할 수 있어요!
                            </CaptionLight>
                            <MemoTextInput
                                value={memo}
                                onChange={(e) => setMemo(e.target.value)}
                                height={136}
                                placeholder="기억해야 할 회원님의 상태를 메모해보세요"
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className="w-full justify-end">
                    <Col
                        className="w-[214px] h-[71px] justify-center items-center rounded-[9px] bg-[#F4F5F7] mr-[16px] cursor-pointer"
                        onClick={() => setIsVisible(false)}
                    >
                        <RegularText
                            fontSize={20}
                            lineHeight={24}
                            color={'#3D4244'}
                            onClick={() => setIsVisible(false)}
                        >
                            닫기
                        </RegularText>
                    </Col>

                    <Col
                        className="w-[214px] h-[71px] justify-center items-center rounded-[9px] bg-primary cursor-pointer"
                        onClick={onClickUpdateMembership}
                    >
                        <RegularText fontSize={20} lineHeight={24} color={'white'}>
                            수정 완료
                        </RegularText>
                    </Col>
                </Row>
                {isCalendarOpen && (
                    <NewCalendar
                        date={paymentDate || dayjs(paymentDate)}
                        setIsCalendar={setIsCalendarOpen}
                        onClickDate={(day) => {
                            setPaymentDate(dayjs(day).toISOString());
                        }}
                        isAllDate={false}
                        top={200}
                        left={400}
                        selectedDay={dayjs(paymentDate)}
                        isNextMonth
                        isPrevMonth
                        titleFormat="YYYY년 MM월"
                    />
                )}
            </Col>
            {isMembershipNameModalVisible && (
                <ModalUpdateMembershipName
                    onUpdateComplete={(value: string) => {
                        setMembershipName(value);
                        setIsMembershipNameModalVisible(false);
                    }}
                    setIsVisible={setIsMembershipNameModalVisible}
                    value={membershipName}
                />
            )}
            {isSalesManagerModalVisible && (
                <ModalSalesManagerName
                    setIsVisible={setIsSalesManagerModalVisible}
                    onClickConfirm={(name: string) => {
                        postSalesManagerMutation.mutate({
                            membershipId: authObject.centerId,
                            managerName: name,
                        });
                        setSalesManagerName(name);
                    }}
                />
            )}

            {isAlertModalVisible && <ModalUpdateAlert setIsVisible={setIsAlertModalVisible} />}
        </Modal>
    );
};

export default ModalMembershipUpdate;
